import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { Tooltip } from '@mui/material'

import {
  ORDER_PAGE,
  PX_TO_REM,
  TEMPLATE_PAGE,
  WORKFLOW_PAGE,
} from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'

import Select from '../../../../components/Select'
import RadioGroup from '../../../../components/RadioGroup'
import Typography from '../../../../components/Typography'

import DotAnimation from '../../../../layouts/Common/DotAnimation'

import TableContent from '../../../Templates/TableContent'

import './index.scss'

const NewOrderTop = ({
  newOrderTemplate,
  isTestOrder,
  templateType,
  onOrderNameChange,
  isIframeTemplate,
}) => {
  const getTemplates = useStoreActions((state) => state.user.getTemplates)
  const getTemplateDetail = useStoreActions((actions) => actions.template.getTemplateDetail)
  const updateTemplateValue = useStoreActions((actions) => actions.order.updateTemplateValue)
  const updateOrder = useStoreActions((actions) => actions.order.updateOrder)
  const getNewOrderPricing = useStoreActions((actions) => actions.order.getNewOrderPricing)
  const setWebOrderTemplateId = useStoreActions((actions) => actions.order.setWebOrderTemplateId)
  const setWebOrderName = useStoreActions((actions) => actions.order.setWebOrderName)

  const templates = useStoreState((state) => state.user.templates)
  const webOrderTemplateId = useStoreState((state) => state.order.webOrderTemplateId)

  const [orderName, setOrderName] = useState('')
  const [turnaroundTime, setTurnaroundTime] = useState('')
  const [templateContentData, setTemplateContentData] = useState({})
  const [isTemplateLoading, setIsTemplateLoading] = useState(false)

  const webOrderTemplateIdRef = useRef(0)
  const webOrderTemplateTurnaroundRef = useRef(null)
  const handleTurnaroundTimeChange = async (e) => {
    setTurnaroundTime(e.target.value)
    if (templateType === ORDER_PAGE) {
      await updateOrder({ order_id: newOrderTemplate?.id, id: 'turnaround', value: e.target.value })
    } else {
      await updateTemplateValue({ template_id: newOrderTemplate?.template_id, id: 'turnaround', value: e.target.value })
      if (!isIframeTemplate) {
        getNewOrderPricing({
          template_id: webOrderTemplateId || newOrderTemplate?.template_id,
          is_images_from_web: webOrderTemplateId ? 1 : 0,
        })
      }
    }
  }

  const updateSelectedTemplate = async (id) => {
    setWebOrderTemplateId(id)
    webOrderTemplateIdRef.current = id

    setIsTemplateLoading(true)
    const tableType = 'workflows'
    const data = await getTemplateDetail({ id, tableType })
    setIsTemplateLoading(false)

    setTemplateContentData(data)
    webOrderTemplateTurnaroundRef.current = data?.template.turnaround
  }

  useEffect(() => {
    setOrderName(newOrderTemplate?.name)
    setWebOrderName(newOrderTemplate?.name)
    onOrderNameChange(newOrderTemplate?.name)
    setTurnaroundTime(newOrderTemplate?.turnaround)
  }, [newOrderTemplate])

  useEffect(() => {
    getTemplates()
  }, [])

  useEffect(() => {
    if (templates.length === 0) return
    if (!webOrderTemplateId) return
    updateSelectedTemplate(webOrderTemplateId).then(() => { })
  }, [templates])

  const turnaroundValues = {
    8: (
      <>
        8h
        {
          newOrderTemplate?.turnaround_prices[8] && (
            <span className="price-badge">{newOrderTemplate?.turnaround_prices[8]}</span>
          )
        }
      </>
    ),
    12: (
      <>
        12h
        {
          newOrderTemplate?.turnaround_prices[12] && (
            <span className="price-badge">{newOrderTemplate?.turnaround_prices[12]}</span>
          )
        }
      </>
    ),
    24: (
      <>
        24h
        {
          newOrderTemplate?.turnaround_prices[24] && (
            <span className="price-badge">{newOrderTemplate?.turnaround_prices[24]}</span>
          )
        }
      </>
    ),
    48: (
      <>
        48h
        {
          newOrderTemplate?.turnaround_prices[48] && (
            <span className="price-badge">{newOrderTemplate?.turnaround_prices[48]}</span>
          )
        }
      </>
    ),
  }

  const transformData = (data) => {
    // if all disabled, we should wrap all values in the turnaroundValues object with a tooltip
    const allDisabled = ((templateType && newOrderTemplate?.is_template_disabled === true)
      || newOrderTemplate?.is_retail_ready === true)
    if (allDisabled && !isIframeTemplate) {
      Object.keys(data).forEach((key) => {
        // eslint-disable-next-line no-param-reassign
        data[key] = (
          <Tooltip
            arrow
            className="info-tooltip"
            PopperProps={{
              disablePortal: true,
            }}
            placement="left"
            disableFocusListener
            disableTouchListener
            title={Translation.disabled_for_retail}
          >
            <span className="disabled">{data[key]}</span>
          </Tooltip>
        )
      })
    } else if (isTestOrder) {
      Object.keys(data).forEach((key) => {
        if (key === '8' || key === '12') {
          // eslint-disable-next-line no-param-reassign
          data[key] = (
            <Tooltip
              arrow
              className="info-tooltip w-max-314"
              PopperProps={{
                disablePortal: true,
              }}
              placement="top"
              disableFocusListener
              disableTouchListener
              title={Translation.test_order_disabled_tooltip_text}
            >
              <span className="disabled">{data[key]}</span>
            </Tooltip>
          )
        }
      })
    }

    return data
  }

  const sortSuffix = 'id'

  const handleWorkflowChange = async (e) => {
    const id = parseInt(e.target.value.replace(new RegExp(`${sortSuffix}$`), ''), 10)
    if (id) await updateSelectedTemplate(id)
    getNewOrderPricing({
      template_id: id || newOrderTemplate?.template_id,
      is_images_from_web: id ? 1 : 0,
    })
  }

  const handleFirstWorkflow = async () => {
    const id = webOrderTemplateIdRef.current || templates[0]?.id
    if (id) await updateSelectedTemplate(id)
    getNewOrderPricing({
      template_id: id || newOrderTemplate?.template_id,
      is_images_from_web: id ? 1 : 0,
    })

    // show web upload tab
    document.getElementById('upload-tab-web')?.click()
  }

  const handleWorkflowReset = () => {
    const id = 0
    setWebOrderTemplateId(id)
    setTemplateContentData({})
    getNewOrderPricing({
      template_id: id || newOrderTemplate?.template_id,
      is_images_from_web: id ? 1 : 0,
    })
  }

  const transformTemplatesData = (data) => data.reduce((acc, template) => {
    acc[`${template.id}${sortSuffix}`] = template.name // Use 'id' as the key and 'name' as the label
    return acc
  }, {})

  return (
    <>
      {!templateType && templates.length > 0 && (
        <section className="top-info">
          <div className="top-info-box">
            <div>
              <span
                className={`workflows-tab ${!webOrderTemplateId ? 'active' : ''}`}
                onClick={handleWorkflowReset}
              >
                <Typography
                  variant="subtitle"
                  font="semibold"
                  lineHeight={PX_TO_REM['20']}
                  label={Translation.custom_settings}
                  inline
                />
              </span>
              {' '}
              <span
                className={`workflows-tab ${webOrderTemplateId ? 'active' : ''}`}
                onClick={handleFirstWorkflow}
              >
                <Typography
                  variant="subtitle"
                  font="semibold"
                  lineHeight={PX_TO_REM['20']}
                  label={Translation.workflow}
                  inline
                />
              </span>
            </div>

            {webOrderTemplateId > 0 && (
              <>
                <div className="top-info-box--workflows">
                  <div>
                    <Select
                      isEmptyOption={false}
                      name="info-box-turnaround"
                      value={`${webOrderTemplateId}${sortSuffix}`}
                      values={transformTemplatesData(templates)}
                      onChange={(e) => handleWorkflowChange(e)}
                    />
                  </div>
                  <div>
                    <input
                      id="new-order-name"
                      type="text"
                      placeholder={Translation.new_order_name_input_placeholder}
                      className="info-box-input"
                      value={orderName}
                      onChange={(e) => {
                        setOrderName(e.target.value)
                        setWebOrderName(e.target.value)
                        onOrderNameChange(e.target.value)
                      }}
                      onBlur={() => {
                        if (templateType === ORDER_PAGE) {
                          updateOrder({ order_id: newOrderTemplate?.id, id: 'name', value: orderName })
                        } else {
                          updateTemplateValue({ template_id: newOrderTemplate?.template_id, id: 'name', value: orderName })
                        }
                      }}
                    />
                  </div>

                  {webOrderTemplateTurnaroundRef.current && (
                    <div>
                      <Typography
                        variant="subtitle"
                        lineHeight={PX_TO_REM['36']}
                        label={Translation.turnaround_time}
                        inline
                      />
                      :
                      {' '}
                      <Typography
                        variant="subtitle"
                        font="semibold"
                        lineHeight={PX_TO_REM['36']}
                        label={`${webOrderTemplateTurnaroundRef.current}h`}
                        inline
                      />
                    </div>
                  )}
                </div>

                {isTemplateLoading ? (
                  <div className="align-center">
                    <DotAnimation />
                  </div>
                ) : templateContentData && Object.keys(templateContentData).length > 0 && (
                  <TableContent data={templateContentData} isPadding={false} />
                )}
              </>
            )}
          </div>
        </section>
      )}

      {!webOrderTemplateId && (
        <section className="top-info">
          <div className="top-info-box">
            <Typography
              variant="subtitle"
              font="semibold"
              lineHeight={PX_TO_REM['20']}
              label={
                // eslint-disable-next-line no-nested-ternary
                templateType
                  // eslint-disable-next-line no-nested-ternary
                  ? (templateType === WORKFLOW_PAGE
                    ? Translation.workflow_name : templateType === ORDER_PAGE
                      ? Translation.order_name : Translation.template_name)
                  : Translation.template_name
              }
            />
            <input
              id="new-order-name"
              type="text"
              placeholder={Translation.new_order_name_input_placeholder}
              className="info-box-input"
              width="100%"
              value={orderName}
              onChange={(e) => {
                setOrderName(e.target.value)
                setWebOrderName(e.target.value)
                onOrderNameChange(e.target.value)
              }}
              onBlur={() => {
                if (templateType === ORDER_PAGE) {
                  updateOrder({ order_id: newOrderTemplate?.id, id: 'name', value: orderName })
                } else {
                  updateTemplateValue({ template_id: newOrderTemplate?.template_id, id: 'name', value: orderName })
                }
              }}
            />
            {
              !templateType && !isTestOrder && (
                <Typography variant="xs" label={Translation.new_order_name_input_bottom_text} />
              )
            }
          </div>
          <div className="top-info-box">
            <Typography
              variant="subtitle"
              font="semibold"
              lineHeight={PX_TO_REM['20']}
              label={Translation.turnaround_time}
            />
            <div className="info-box-turnaround">
              <RadioGroup
                name="info-box-turnaround"
                value={turnaroundTime}
                values={transformData(turnaroundValues)}
                color="gray"
                row
                disabledData={isTestOrder ? ['8', '12'] : []}
                onChange={(e) => handleTurnaroundTimeChange(e)}
                allDisabled={((templateType && newOrderTemplate?.is_template_disabled === true)
                  || newOrderTemplate?.is_retail_ready === true) && !isIframeTemplate}
              />
            </div>
            <Typography
              variant="xs"
              containsHtml
              label={Translation.turnaround_time_text
                .replace('{start_h}', newOrderTemplate?.start_hour)
                .replace('{end_h}', newOrderTemplate?.eta_hours[turnaroundTime])}
            />
          </div>
        </section>
      )}
    </>
  )
}

NewOrderTop.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  newOrderTemplate: PropTypes.object,
  isTestOrder: PropTypes.bool,
  templateType: PropTypes.oneOf(['', TEMPLATE_PAGE, WORKFLOW_PAGE, ORDER_PAGE]),
  onOrderNameChange: PropTypes.func.isRequired,
  isIframeTemplate: PropTypes.bool,
}

NewOrderTop.defaultProps = {
  newOrderTemplate: {},
  isTestOrder: false,
  templateType: '',
  isIframeTemplate: false,
}

export default NewOrderTop
