import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'
import { useStoreActions } from 'easy-peasy'

import { ORDER_PAGE, TEMPLATE_PAGE, WORKFLOW_PAGE } from '../../../../helpers/Constants'
import { DOOPIC_URLS, Translation } from '../../../../helpers/Translation'

import Typography from '../../../../components/Typography'

import NewOrderModal from '../../Common/NewOrderModal'
import InfoModal from '../../Common/InfoModal'

import { ReactComponent as PlusFaqSvg } from '../../../../svg/plus_faq.svg'
import { ReactComponent as CloseRoundedSvg } from '../../../../svg/close_rounded.svg'
import { ReactComponent as RefreshSvg } from '../../../../svg/refresh.svg'

import './index.scss'

const Tab = ({
  newOrderTemplate,
  activeTab,
  setActiveTab,
  isIframeTemplate,
  templateType,
  editTemplateId,
  isTestOrder,
}) => {
  const getNewOrderTemplate = useStoreActions((actions) => actions.order.getNewOrderTemplate)
  const getEditTemplate = useStoreActions((actions) => actions.order.getEditTemplate)
  const getOrder = useStoreActions((actions) => actions.order.getOrder)
  const addNewOrderFormat = useStoreActions((actions) => actions.order.addNewOrderFormat)
  const deleteNewOrderFormat = useStoreActions((actions) => actions.order.deleteNewOrderFormat)
  const resetFormatActions = useStoreActions((actions) => actions.order.resetFormatActions)
  const [clickFunctionType, setClickFunctionType] = useState('')
  const [showResetModal, setShowResetModal] = useState(false)

  const handleAddNewFormat = async () => {
    const tabCount = newOrderTemplate?.template_formats?.length
    const handleAddNewFormatResult = await addNewOrderFormat({
      id: templateType === ORDER_PAGE ? newOrderTemplate?.id : newOrderTemplate?.template_id,
      type: templateType,
    })
    if (handleAddNewFormatResult) {
      if (templateType) {
        if (templateType === ORDER_PAGE) getOrder(newOrderTemplate?.id)
        else getEditTemplate(editTemplateId)
      } else {
        getNewOrderTemplate()
      }
      setActiveTab(tabCount + 1)
      setClickFunctionType('')
    }
  }

  const handleDeleteFormat = async () => {
    const handleDeleteFormatResult = await deleteNewOrderFormat({
      id: newOrderTemplate?.template_formats[activeTab - 1]?.id,
      type: templateType,
    })
    if (handleDeleteFormatResult) {
      if (templateType) {
        if (templateType === ORDER_PAGE) getOrder(newOrderTemplate?.id)
        else getEditTemplate(editTemplateId)
      } else {
        getNewOrderTemplate()
      }
      setActiveTab(1)
      setClickFunctionType('')
    }
  }

  return (
    <div>
      <div className="tabs">
        <div className="format-options">
          {
            newOrderTemplate?.template_formats?.map((format, index) => (
              <div className="format-tabBtn-wrapper" key={format.id}>
                <button
                  key={format.id}
                  type="button"
                  className={`format-tabBtn ${activeTab === index + 1 ? 'active' : ''}`}
                  onClick={() => setActiveTab(index + 1)}
                >
                  <Typography variant="xs" font="semibold" label={`${index + 1}. ${Translation.format}`} />
                </button>
                {
                  isIframeTemplate || (activeTab === index + 1 && (typeof newOrderTemplate?.is_template_disabled === 'undefined'
                  || newOrderTemplate?.is_template_disabled === false) && newOrderTemplate?.template_formats?.length > 1) ? (
                    <button type="button" className="format-tabBtn-close" onClick={() => setClickFunctionType('delete')}>
                      <CloseRoundedSvg />
                    </button>
                    ) : null
                }
              </div>
            ))
          }
          {
            isIframeTemplate || (typeof newOrderTemplate?.is_template_disabled === 'undefined'
            || newOrderTemplate?.is_template_disabled === false) ? (
              <Tooltip
                className="info-tooltip w-max-314"
                PopperProps={{
                  disablePortal: true,
                }}
                title={isTestOrder ? Translation.test_order_disabled_tooltip_text : Translation.add_new_format}
                placement={isTestOrder ? 'top-start' : 'top'}
                arrow
              >
                <button
                  type="button"
                  className="format-new-btn"
                  onClick={() => setClickFunctionType('add')}
                  disabled={isTestOrder}
                >
                  <PlusFaqSvg />
                </button>
              </Tooltip>
              ) : null
          }
          <InfoModal
            labelText={Translation.format}
            modalText={Translation.new_order_tab_info_modal_text.replace(/%DOOPIC_URL_PRICES%/g, DOOPIC_URLS.prices)}
          />
        </div>
        {(typeof newOrderTemplate?.is_template_disabled === 'undefined'
        || newOrderTemplate?.is_template_disabled === false || isIframeTemplate) && (
          <button type="button" className="reset-button" onClick={() => setShowResetModal(true)}>
            <Typography variant="xs" font="medium" label={`${Translation.reset} ${activeTab}. ${Translation.format}`} />
            <RefreshSvg />
          </button>
        )}
      </div>
      <NewOrderModal
        isShown={clickFunctionType}
        onClickOutside={() => setClickFunctionType('')}
        title={Translation.attention}
        contentText={clickFunctionType === 'add' ? Translation.add_format_modal_text : Translation.delete_format_modal_text}
        leftButtonClick={() => setClickFunctionType('')}
        rightButtonClick={clickFunctionType === 'add' ? handleAddNewFormat : handleDeleteFormat}
      />
      <NewOrderModal
        isShown={showResetModal}
        onClickOutside={() => setShowResetModal(false)}
        title={Translation.attention}
        contentText={Translation.reset_modal_text}
        leftButtonClick={() => setShowResetModal(false)}
        rightButtonClick={() => {
          resetFormatActions({
            id: newOrderTemplate?.template_formats[activeTab - 1]?.id,
            type: templateType,
          })
          setShowResetModal(false)
        }}
      />
    </div>
  )
}

Tab.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  newOrderTemplate: PropTypes.object,
  activeTab: PropTypes.number,
  setActiveTab: PropTypes.func,
  isIframeTemplate: PropTypes.bool,
  templateType: PropTypes.oneOf(['', TEMPLATE_PAGE, WORKFLOW_PAGE, ORDER_PAGE]),
  editTemplateId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isTestOrder: PropTypes.bool,
}

Tab.defaultProps = {
  newOrderTemplate: {},
  activeTab: 1,
  setActiveTab: () => { },
  isIframeTemplate: false,
  templateType: '',
  editTemplateId: null,
  isTestOrder: false,
}

export default Tab
