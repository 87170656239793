export const DOOPIC_URLS_EN = {
  home: process.env.REACT_APP_DOOPIC_URL,
  support: '/support',
  contact: `${process.env.REACT_APP_DOOPIC_URL}/en/contact`,
  prices: `${process.env.REACT_APP_DOOPIC_URL}/en/pricing`,
  terms: `${process.env.REACT_APP_DOOPIC_URL}/en/terms-and-conditions`,
  privacy: `${process.env.REACT_APP_DOOPIC_URL}/en/data-protection`,
}

export const DOOPIC_URLS_DE = {
  home: process.env.REACT_APP_DOOPIC_URL,
  support: '/support',
  contact: `${process.env.REACT_APP_DOOPIC_URL}/kontakt`,
  prices: `${process.env.REACT_APP_DOOPIC_URL}/preise`,
  terms: `${process.env.REACT_APP_DOOPIC_URL}/agb`,
  privacy: `${process.env.REACT_APP_DOOPIC_URL}/datenschutz`,
}

export const API_ENDPOINTS = {
  login: '/login/session',
  logout: '/logout',
  forgot: '/forgot',
  validateToken: '/reset/validate',
  register: '/register',
  reset: '/reset',
  setNewPassword: '/reset/terms',
  user: '/user',
  user_update: '/user/{id}',
  user_accounts: '/user/accounts',
  user_account: '/user/account/',
  user_account_new: 'user/account/new',
  me: '/user/account/me',
  user_account_password_update: '/user/account/password/',
  user_account_avatar: 'user/account/avatar',
  user_account_email_type_update: '/user/account/email/type/{id}',
  user_account_forgot: 'user/account/forgot',
  user_account_pending_confirmation: '/user/account/pending/confirmation',
  user_account_mapping: '/user/account/key/mapping',
  public_user_accounts: '/public/user/accounts',
  public_user_account: '/public/user/account',
  public_user_account_new: 'public/user/account/new',
  public_user_account_update: '/public/user/account/{id}',
  invoices: '/invoices',
  orders: '/orders',
  order_update: '/order/{id}',
  order_details: '/order/details/{id}',
  order_create: '/order/create',
  order_format: '/order/format/{id}',
  new_order_image_upload: '/image/upload',
  selected_test_image: '/template/image/test/{id}',
  csv_upload: '/csv/upload',
  new_order_template: '/order/template/new',
  new_order_template_test: '/order/template/test',
  template_format_actions: '/format/actions/{id}',
  order_format_actions: '/order/format/actions/{id}',
  new_order_pricing: '/order/template/pricing/{template_id}/{is_images_from_web}',
  order_details_images: '/order/details/images/{id}',
  template_format_actions_update: '/format/actions/update/{id}',
  order_format_actions_update: '/order/format/actions/update/{id}',
  format_actions_reset: '/format/actions/reset/{id}',
  template_format: '/template/format/{id}',
  update_template_value: '/template/{id}',
  add_coupon_code: '/coupon/validate',
  delete_coupon_code: '/coupon/delete',
  order_prioritise: '/order/prioritise/{id}',
  order_test_image_confirm: '/order/image/test/{id}',
  order_search_images: '/search/images',
  order_accept_price: '/order/price/accept/{id}',
  rating: '/rating',
  ekomi_webhook: '/doohook/ekomi/{id}',
  ftp: '/ftp',
  ftp_folders: '/order/ftp/folders/{referrer}',
  ftp_order_create: '/order/ftp/create',
  ftp_request: '/ftp/request',
  ftp_request_done: '/ftp/request/done',
  ftp_password: '/ftp/password',
  userTemplates: '/templates/user/account',
  templates: 'templates',
  template: '/template/{id}',
  template_details: '/template/details/{id}',
  template_assign: 'templates/user/account/assign',
  template_unassign: 'templates/user/account/unassign',
  template_reassign: '/templates/user/account/reassign',
  template_duplicate: '/template/duplicate/{id}',
  public_gallery: '/public/gallery',
  public_gallery_reassign: '/public/galleries/user/account/reassign',
  industry_branches: '/industry/branches',
  folders: '/folders',
  payment_methods: '/payment/methods',
  payment_method: '/payment/method',
  paymentPrimary: '/payment/method/primary',
  support: '/support',
  unpaid_details: '/unpaid/details',
  unpaid_details_checkout: '/unpaid/details/checkout',
  unpaid_details_dashboard: '/unpaid/details/dashboard',
  email_confirmation: '/email/confirmation',
  dashboard: '/dashboard',
  countries: '/countries',
  payWithCreditCard: '/pay/creditcard',
  payWithSepa: '/pay/sepa',
  payWithGiropay: '/pay/giropay',
  payWithSofort: '/pay/sofort',
  createInvoice: '/create/invoice',
  switchAccount: '/switch/user/account',
  switchToRealAccount: '/switch/real/user/account',
  googleSignIn: '/google/signin',
  downloadInvoice: '/invoices/download/{id}',
  templateEqual: '/template/equal/{id}',
  templateOrderSave: '/template/order/save/{id}',
  templateOrderApply: '/template/order/apply/{id}',
  templateApply: '/template/apply/{id}',
  templateOrderUpdate: '/template/order/update/{id}',
  unsubscribeFromMailing: '/mailing/unsubscribe/:lang/:user_account_id_code/:mailing_id_code',
  jsError: '/js/error',
}
