import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions } from 'easy-peasy'

import {
  ORDER_PAGE, PX_TO_REM, TEMPLATE_PAGE, WORKFLOW_PAGE,
} from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'

import RadioGroup from '../../../../components/RadioGroup'
import Typography from '../../../../components/Typography'

import './index.scss'

const BackupOptions = ({
  newOrderTemplate,
  isTestOrder,
  templateType,
  isIframeTemplate,
}) => {
  const updateTemplateValue = useStoreActions((actions) => actions.order.updateTemplateValue)
  const updateOrder = useStoreActions((actions) => actions.order.updateOrder)
  const getNewOrderPricing = useStoreActions((actions) => actions.order.getNewOrderPricing)
  const [backup, setBackups] = useState('')

  const handleBackupsChange = async (e) => {
    setBackups(e.target.value)
    if (templateType === ORDER_PAGE) {
      await updateOrder({ order_id: newOrderTemplate?.id, id: 'backup', value: e.target.value })
    } else {
      await updateTemplateValue({ template_id: newOrderTemplate?.template_id, id: 'backup', value: e.target.value })
    }
    if (!isIframeTemplate) {
      getNewOrderPricing({ template_id: newOrderTemplate?.template_id })
    }
  }

  useEffect(() => {
    setBackups(newOrderTemplate?.backup)
  }, [newOrderTemplate])

  const backupValues = {
    '4w': (
      <>
        4 Weeks
        {
            newOrderTemplate?.backup_prices['4w'] && (
              <span className="price-badge uppercase">{Translation.free}</span>
            )
        }
      </>
    ),
    '3m': (
      <>
        3 Months
        {
            newOrderTemplate?.backup_prices['3m'] && (
              <span className="price-badge">{newOrderTemplate?.backup_prices['3m']}</span>
            )
        }
      </>
    ),
    '1y': (
      <>
        1 Year
        {
            newOrderTemplate?.backup_prices['1y'] && (
              <span className="price-badge">{newOrderTemplate?.backup_prices['1y']}</span>
            )
        }
      </>
    ),
    '2y': (
      <>
        2 Years
        {
            newOrderTemplate?.backup_prices['2y'] && (
              <span className="price-badge">{newOrderTemplate?.backup_prices['2y']}</span>
            )
        }
      </>
    ),
  }

  return (
    <section className="top-info">
      <div className="top-info-box">
        <Typography variant="subtitle" font="semibold" lineHeight={PX_TO_REM['20']} label={Translation.backups} />
        <div className="info-box-turnaround">
          <RadioGroup
            name="info-box-turnaround"
            value={backup}
            values={backupValues}
            color="gray"
            row
            onChange={(e) => handleBackupsChange(e)}
            disabledData={isTestOrder ? ['8', '12'] : []}
            allDisabled={((templateType && newOrderTemplate?.is_template_disabled === true)
              || newOrderTemplate?.is_retail_ready === true) && !isIframeTemplate}
          />
        </div>
        <Typography
          variant="xs"
          containsHtml
          label={Translation.backups_explanation_text}
        />
      </div>
    </section>
  )
}

BackupOptions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  newOrderTemplate: PropTypes.object,
  isTestOrder: PropTypes.bool,
  templateType: PropTypes.oneOf(['', TEMPLATE_PAGE, WORKFLOW_PAGE, ORDER_PAGE]),
  isIframeTemplate: PropTypes.bool,
}

BackupOptions.defaultProps = {
  newOrderTemplate: {},
  isTestOrder: false,
  templateType: '',
  isIframeTemplate: false,
}

export default BackupOptions
