import React from 'react'
import PropTypes from 'prop-types'

import { PX_TO_REM } from '../../../helpers/Constants'
import { Translation } from '../../../helpers/Translation'

import Typography from '../../../components/Typography'

import DotAnimation from '../../../layouts/Common/DotAnimation'

import ContentAccordion from '../../../layouts/Common/ContentAccordion'

import { ReactComponent as LayersSvg } from '../../../svg/layers.svg'

import './index.scss'

const TableContent = ({ data, isPadding = true }) => (
  <div className={`table-content ${!isPadding ? 'no-padding' : ''}`}>
    {
      Object.keys(data).length > 0 ? (
        <>
          <div className={`content-title ${!isPadding ? 'no-margin-top' : ''}`}>
            <LayersSvg />
            <Typography
              variant="h6"
              label={Translation.formats}
              font="semibold"
              lineHeight={PX_TO_REM['26']}
            />
          </div>
          {
            data?.template_actions?.length > 0 && (
              data?.template_actions?.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ContentAccordion key={item.label} data={item} index={index} />
              ))
            )
          }
        </>
      ) : (
        <div className="table-animation">
          <DotAnimation />
        </div>
      )
    }
  </div>
)

export default TableContent

TableContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isPadding: PropTypes.bool,
}

TableContent.defaultProps = {
  data: {},
  isPadding: true,
}
